<template>
    <div class="split-area" :class='{ "trip-selected": selectedTrip != null }'>
        <div class="map-container">
            <div id="map"></div>
            <div class="map-cover" v-if="loading">
            </div>
        </div>
        <div class="panel">
            <div class="panel-content dark-bg">
                <h1>How far can you get?</h1>
                <p>Text here.</p>
            </div>
            <div class="panel-content">
                <div class="fields-area">
                    <div class="form-field">
                        <label>Transfers</label>
                        <n-switch v-model:value="transfers" />
                    </div>

                    <div class="form-field">
                        <label>Total travel time</label>
                        <p>Includes walking, waiting and riding.</p>
                        <n-button-group>
                            <n-button round v-on:click="minutes = 20">
                                20 min.
                            </n-button>
                            <n-button ghost v-on:click="minutes = 30">
                                30 min.
                            </n-button>
                            <n-button round v-on:click="minutes = 40">
                                40 min.
                            </n-button>
                            </n-button-group>
                    </div>

                    <div class="form-field">
                        <label>Day &amp; time</label>
                        <n-dropdown
                                    trigger="click"
                                    @select="changeTime"
                                    :options="timeOptions"
                                >
                                    <n-button color="#ffffff" text-color="#111111">{{ selectedTimeOfDay.label }}</n-button>
                                </n-dropdown>
                    </div>

                    <div class="form-field">
                        <label>Maximum walk to &amp; from stops</label>
                        <n-button-group>
                            <n-button round v-on:click="walk = 5">
                                5 minutes
                            </n-button>
                            <n-button round v-on:click="walk = 10">
                                10 minutes
                            </n-button>
                            </n-button-group>
                    </div>

                    <div class="form-field">
                        <label>Walking speed</label>
                        <n-button-group>
                            <n-button round v-on:click="walk_speed = 'slower'">
                                70% of average
                            </n-button>
                            <n-button round v-on:click="walk_speed = 'average'">
                                Average
                            </n-button>
                        </n-button-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import { NSwitch, NButton, NButtonGroup, NDropdown } from 'naive-ui'

export default {
  name: 'Isochrones',
  data: function(){
      return {
          map: null,
          origin: null,
          loading: false,
          minutes: 20,
          walk: 10,
          walk_speed: 'average',
          transfers: true,
          selectedTimeOfDayIndex: 0,
          timeOptions: [
                {
                    label: 'Weekday, 8am',
                    key: 'weekday-8am'
                },
                {
                    label: "Weekday, Noon",
                    key: "weekday-12pm"
                },
                {
                    label: 'Weekday, 8pm',
                    key: 'weekday-8pm'
                },
                {
                    label: 'Weekday, 11pm',
                    key: 'weekday-> 10pm'
                },
                {
                    label: 'Saturday, mid-day',
                    key: 'weekend-2pm'
                }
            ]
      }
  },
  mounted: function(){
      this.setupMap();
  },
  components: {
      NSwitch,
      NButton,
      NButtonGroup,
      NDropdown
  },
  watch: {
      directionsUrl: function(){
          if(!this.directionsUrl){ return }
          this.loading = true;

          var thisUrl = this.directionsUrl;

          fetch(thisUrl).then((result) => {
                        return result.json();
                    }).then((geoJson) => {
                        if(thisUrl == this.directionsUrl){
                            this.map.getSource('isochrones').setData(geoJson);
                            this.loading = false;
                        }
                    })
      }
  },
  computed: {
      directionsUrl: function(){
            if(!this.origin){ return }

            var url = 'https://2ewmeimg3m.us-west-2.awsapprunner.com/create'
            //var url = 'http://localhost:5000/create';

            url += '?lon=' + this.origin[0]
                + '&lat=' + this.origin[1]
                + '&max_walk=' + this.walk
                + '&travel_time=' + this.minutes
                + '&walk_speed=' + this.walk_speed
                + '&transfer=' + (this.transfers ? 'true' : 'false')
                + '&day_of_week=' + encodeURIComponent(this.selectedTimeOfDay.key.split('-')[0])
                + '&time_of_day=' + encodeURIComponent(this.selectedTimeOfDay.key.split('-')[1])
                + '&scenarios=2022_proposal,2020_pre_covid'

            return url;
      },
      selectedTimeOfDay: function(){
          return this.timeOptions[this.selectedTimeOfDayIndex];
      },
  },
  methods: {
        setupMap: function(){
            mapboxgl.accessToken = 'pk.eyJ1IjoiY2FydmluIiwiYSI6IjdGSUItY1kifQ.dhkr11vInR8pEWrMYMY4gA';
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: 'mapbox://styles/carvin/cku1mip38522m18pe6srplltu', // style URL
                bounds: [-122.55773212971279,37.68668744194065,-122.31798936733333,37.847777582239516]
            });

            this.map.on('load',() => {
                this.map.addSource('isochrones',{
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                })

                this.map.addLayer({
                    'id': 'isochrones-proposal',
                    'type': 'fill-extrusion',
                    'source': 'isochrones',
                    'layout': {},
                    'paint': {
                        'fill-extrusion-color': '#0000ff', 
                        'fill-extrusion-opacity': 0.5
                    },
                    'fill-extrusion-height': 0,
                    'fill-extrusion-base': 0,
                    'filter': ['==','scenario_key','2022_proposal']
                });

                this.map.addLayer({
                    'id': 'isochrones-pre-covid',
                    'type': 'fill-extrusion',
                    'source': 'isochrones',
                    'layout': {},
                    'paint': {
                        'fill-extrusion-color': '#ff0000', 
                        'fill-extrusion-opacity': 0.5
                    },
                    'fill-extrusion-height': 0,
                    'fill-extrusion-base': 0,
                    'filter': ['==','scenario_key','2020_pre_covid']
                });

                this.map.on('click', (e) => {
                    var lat = e.lngLat.lat;
                    var lon = e.lngLat.lng;

                    this.origin = [lon, lat];
                });

            });
      },
      changeTime: function(e){
          var newValue = e;
          var newIndex;

          for(var i = 0; i < this.timeOptions.length; i++){
              if(this.timeOptions[i].key == e){
                  newIndex = i;
                  break;
              }
          }

          if(newIndex != null){
              this.selectedTimeOfDayIndex = newIndex;
          }
      }
  }
}
</script>