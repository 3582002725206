<template>
    <div class="location-search">
    <n-auto-complete 
            :options="options"
            v-on:focus="selectAll"
            v-model:value="query"
            size="large"
            v-on:select="optionSelected"
            v-on:blur="blurred"
            ref="fieldbox"
            :placeholder="placeholder"/>
    <label>{{ label }}:</label>
    </div>
</template>

<script>
import { NAutoComplete } from 'naive-ui'

export default {
    name: 'LocationSearch',
    data: function(){
        return {
            query: '',
            options: [],
            results: [],
            lastFetchIndex: 0,
            lastShownIndex: 0,
            value: null
        }
    },
    props: ['modelValue','placeholder','label'],
    emits: ['update:modelValue'],
    components: {
        NAutoComplete
    },
    methods: {
        selectAll: function(){
            this.$refs.fieldbox.$el.querySelector("input").select();
        },
        optionSelected: function(e){
            this.value = e;
            this.$emit('update:modelValue', e)
        },
        blurred: function(e){
            if(this.options && this.options.length > 0){
                this.value = this.options[0].value;
                this.$emit('update:modelValue', this.options[0].value)
                this.query = this.options[0].label
            } else {
                this.query = '';
            }
        }
    },
    watch: {
        query: function(){
            var cleaned = this.query.trim().toLowerCase();

            if(cleaned.length < 1){ return }

            var queryParam = encodeURIComponent(cleaned);

            this.lastFetchIndex++;
            var thisIndex = this.lastFetchIndex;


            fetch('https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=7qO4E0i5DEJtVQcR-3SCIppmDV3mGZADDbrQyrYkYDo&in=bbox:-122.520742148095%2C37.694936847306636%2C-122.34799246199731%2C37.84027617111363&limit=5&lang=en&q=' + queryParam).then((result) => {
                return result.json();
            }).then((result) => {
                if(this.lastShownIndex > thisIndex){ return }
                
                var current = this.query.trim().toLowerCase();
                if(!current.startsWith(cleaned)){ return }

                var items = result.items ? result.items : [];
                items = items.filter((item) => {
                    return item.position;
                }).map((item) => {
                    item.center = [item.position.lng,item.position.lat];
                    return item;
                })

                this.results = items;
                this.options = items.map((feature) => {
                    return {
                        label: feature.address.label.split(", San Francisco,")[0],
                        value: feature
                    }
                })
            })

            // fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + queryParam + '.json?access_token=pk.eyJ1Ijoic2VhcmNoLW1hY2hpbmUtdXNlci0xIiwiYSI6ImNrN2Y1Nmp4YjB3aG4zZ253YnJoY21kbzkifQ.JM5ZeqwEEm-Tonrk5wOOMw&cachebuster=1631926887022&autocomplete=true&country=us&types=poi%2Caddress%2Cplace&bbox=-122.520742148095%2C37.694936847306636%2C-122.34799246199731%2C37.84027617111363').then((result) => {
            //     return result.json();
            // }).then((result) => {
            //     if(this.lastShownIndex > thisIndex){ return }
            //     var current = this.query.trim().toLowerCase();
            //     if(!current.startsWith(cleaned)){ return }

            //     this.results = result.features;
            //     this.options = result.features.map((feature) => {
            //         return {
            //             label: feature.place_name.split(", San Francisco,")[0],
            //             value: feature
            //         }
            //     })
            // })
        }
    }
}
</script>

<style>
.location-search input, .location-search .n-input__placeholder {
    text-indent: 44px;
}

.location-search {
    position: relative;
}

.location-search label {
    position: absolute;
    top: 1px;
    left: 0;
    height: 38px;
    line-height: 38px;
    max-width: 38px;
    overflow: hidden;
    display: block;
    padding-left: 12px;
    font-weight: 700;
}

.location-search .n-input__placeholder {
    color: #888;
}
</style>