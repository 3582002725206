import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import CompareTrip from "@/views/CompareTrip.vue";
import Isochrones from "@/views/Isochrones.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: CompareTrip,
  },
  {
    path: "/compare-trip",
    name: "Compare a Trip",
    component: CompareTrip,
  },
  {
    path: "/isochrones",
    name: "Isochrones",
    component: Isochrones,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;