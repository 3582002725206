<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
html, body, #app {
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  font-family: Avenir Next, Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -1%;
}

html, body, #app, input, a, p, button, .n-button {
    font-weight: 500;
}


.inner {
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.form-field {
    width: 100%;
    margin-bottom: 12px;
}

.form-field label {
  display: block;
}

p a {
  color: #0374D1;
}

.split-area {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.panel {
    display: block;
    width: 32%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
    box-sizing: border-box;
    white-space: normal;
    overflow: auto;
    min-width: 320px;
}

.panel-content {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 24px;
}

.panel-content.dark-bg {
    background: #EAF4F4;
    padding-bottom: 24px;
}

.map-container {
    width: 68%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

@media(max-width: 1002px){
    .panel {
        width: 320px;
        max-width: none;
    }

    .map-container {
        width: calc(100% - 320px);
        left: 320px;
        right: auto;
    }
}

#map {
    width: 100%;
    height: 100%;
}

.map-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    pointer-events: all;
}
</style>
