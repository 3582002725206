<template>
    <div class="split-area" :class='{ "trip-selected": selectedTrip != null }'>
        <div class="map-container"  :style='{ visibility: (selectedTrip || (breakpoint == "large")) ? "visible" : "hidden" }'>
            <div id="map"></div>
            <div class="map-cover" v-if="loading || !selectedTrip">
                <p v-if="Object.keys(directions).length > 0">Select a trip to view it on the map.</p>
                <p v-else>Enter your trip to the left to get directions.</p>
            </div>
        </div>
        <div class="panel">
            <div class="panel-content dark-bg" :style='{ display: selectedTrip ? "none" : "block" }'>
                <h1>Muni Trip Comparison</h1>
                <p>Select two places in SF to tell how you'd get between them on Muni, with SFMTA's <a href="https://www.sfmta.com/projects/2022-muni-service-network" target="_blank">2022 Muni proposals</a>.</p>
                    <div class="fields-area" :style='{ pointerEvents: loading ? "none" : "auto", opacity: loading ? 0.5 : 1 }'>
                        <div class="form-field field-origin">
                            <LocationSearch label="From" placeholder="Where to start the trip" v-model="origin" />
                        </div>
                        <div class="form-field field-destination">
                            <LocationSearch label="To" placeholder="Where to end the trip" v-model="destination" />
                        </div>
                        <div class="half-fields">
                            <div class="form-field dropdown-form-field form-field-half">
                                <n-dropdown
                                    trigger="click"
                                    @select="changeTime"
                                    :options="timeOptions"
                                >
                                    <n-button color="#ffffff" text-color="#111111">{{ selectedTimeOfDay.label }}</n-button>
                                </n-dropdown>
                            </div>
                            <div class="form-field dropdown-form-field form-field-half">
                                <n-dropdown
                                    trigger="click"
                                    @select="changeWalkSpeed"
                                    :options="walkSpeedOptions"
                                >
                                    <n-button color="#ffffff" text-color="#111111">{{ selectedWalkSpeed.label.split(' (')[0].split(' speed')[0].replace('Average','Avg.') }} walk speed</n-button>
                                </n-dropdown>
                            </div>
                        </div>
                    </div>
                <n-button v-on:click="getDirections" color="#253D5B" type="primary" size="large" :disabled="!origin || !destination || loading">Compare</n-button>
            </div>
            <div class="panel-content" :style='{ display: selectedTrip ? "none" : "block" }'>
                <p v-if="directions && Object.keys(directions).length > 0">Results include the average wait, walking time and riding time.</p>
                <div v-for="scenario in scenarios" v-if="directions && Object.keys(directions).length > 0">
                    <div v-if="directions[scenario.key]">
                    <h2 class="trip-group-title">{{ scenario.name }}</h2>
                    <a class="trip-option" href="#" v-for="trip_key in Object.keys(directions[scenario.key])" v-on:click.prevent="selectedTrip = directions[scenario.key][trip_key]">
                        <div class="route-codes">
                            <div class="route-code" :style='{ backgroundColor: leg.color, color: "#fff" }' v-for="leg in directions[scenario.key][trip_key].legs">
                                {{ leg.friendly_code }}
                            </div>
                        </div>
                        <div class="route-info">
                            <div class="route-info-total">Takes {{ Math.round(directions[scenario.key][trip_key].total_time) }}&nbsp;min</div>
                            <div class="route-info-walking">Walking: {{ directions[scenario.key][trip_key].total_walking_time }}&nbsp;min</div>
                        </div>
                        <div style="clear: both; height: 0; overflow: hidden">&nbsp;</div>
                        </a>
                    </div>
                </div>
                <div v-else-if="loading">
                    <div class="spinner-container"><n-spin size="large" /></div>
                </div>
                <div v-else-if="noResults">
                    <p>No results were found for this trip. Note that only routes within San Francisco are calculated, and only trips with one or no transfers are returned.</p>
                </div>
                <div v-else>
                    <p>Enter your trip options above and click &quot;compare&quot; to load trip options.</p>
                </div>
                <p v-if="!loading">Created by <a href="https://twitter.com/chrisarvinsf" target="_blank">Chris Arvin</a></p>
            </div>

            <div class="panel-content dark-bg nav" v-if="selectedTrip">
                <a href="#" v-on:click.prevent="selectedTrip = null" class="back-button">Back</a><h1>Trip details</h1>
            </div>
            <div class="panel-content panel-content-details" v-if="selectedTrip">
                <div class="timeline">
                <n-timeline size="large">
                    <n-timeline-item
                        type="success"
                        color="#111111"
                        :title="'Start at ' + this.origin.address.label.split(', ')[0]"
                    />
                    <n-timeline-item
                        type="success"
                        color="#111111"
                        :title="'Walk to ' + selectedTrip.legs[0].start_stop_name"
                        :time="Math.round(selectedTrip.legs[0].walk_to_stop) + ' min walk'"
                    />
                    <n-timeline-item
                        type="success"
                        :title="'Wait for the ' + selectedTrip.legs[0].friendly_code_long + ' line'"
                        :color='selectedTrip.legs[0].color'
                        :time="(Math.round(selectedTrip.legs[0].headway / 2 * 10) / 10) + ' min average wait. (The frequency here is ' + Math.round(selectedTrip.legs[0].headway * 10) / 10 + ' min.)'"
                    />
                    <n-timeline-item
                        type="success"
                        :title="'Ride to ' + selectedTrip.legs[0].end_stop_name"
                        :color='selectedTrip.legs[0].color'
                        :time="Math.round(selectedTrip.legs[0].travel_time) + ' min ride'"
                    />
                    <n-timeline-item
                        v-if="selectedTrip.legs[1] && selectedTrip.legs[1].start_stop != selectedTrip.legs[0].end_stop"
                        type="success"
                        color="#111111"
                        :title="'Walk to ' + selectedTrip.legs[1].start_stop_name"
                        :time="Math.round(selectedTrip.legs[1].walk_to_stop) + ' min walk'"
                    />
                    <n-timeline-item
                        v-if="selectedTrip.legs[1]"
                        type="success"
                        :color='selectedTrip.legs[1].color'
                        :title="'Wait for the ' + selectedTrip.legs[1].friendly_code_long + ' line'"
                        :time="(Math.round(selectedTrip.legs[1].headway / 2 * 10) / 10) + ' min average wait. (The frequency here is ' + Math.round(selectedTrip.legs[1].headway * 10) / 10 + ' min.)'"
                    />
                    <n-timeline-item
                        v-if="selectedTrip.legs[1]"
                        type="success"
                        :color='selectedTrip.legs[1].color'
                        :title="'Ride to ' + selectedTrip.legs[1].end_stop_name"
                        :time="Math.round(selectedTrip.legs[1].travel_time) + ' min ride'"
                    />
                    <n-timeline-item
                        type="success"
                        color="#111111"
                        :title="'Walk to ' + this.destination.address.label.split(', ')[0]"
                        :time="Math.round(selectedTrip.walk_to_end) + ' min walk'"
                    />
                </n-timeline>
                <p>
                    Total trip time: <strong>{{ selectedTrip.total_time }} minutes</strong>
                </p>
                <p>
                    Total walking time: <strong>{{ selectedTrip.total_walking_time }} minutes</strong>
                </p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import LocationSearch from '@/components/LocationSearch.vue'
import { NButton, NSpin, NDropdown, NTimeline, NTimelineItem } from 'naive-ui'
import mapboxgl from 'mapbox-gl'
import { decode as decodePolyline } from '../utils/polyline.js';

export default {
  name: 'CompareTrip',
  data: function(){
      return {
          origin: null,
          destination: null,
          directions: {},
          windowSize: window.innerWidth || document.documentElement.clientWidth || 
document.body.clientWidth,
          loading: false,
          map: null,
          directionsBbox: null,
          route_colors: {
                                "N": "#00559B",
                                "J": "#EC8000",
                                "K": "#00BDBD",
                                "KT": "#00BDBD",
                                "T": "#C20069",
                                "L": "#A424D1",
                                "M": "#009A47",
                                "Bus": "#0374D1",
                                "Rapid": "#DF0000"
                            },
          casing_colors: {
              "Rapid": "#A40000",
              "T": "#970051",
              "L": "#7800A1",
              "Bus": "#004883",
              "N": "#00335E",
              "K": "#009494",
              "KT": "#009494",
              "M": "#007335",
              "J": "#D95A13"
          },
          selectedWalkSpeedIndex: 0,
          walkSpeedOptions: [
                {
                    label: 'Average (5km/h)',
                    key: 'average'
                },
                {
                    label: "70% speed (3.5km/h)",
                    key: "slower"
                }
            ],
          selectedTimeOfDayIndex: 0,
          timeOptions: [
                {
                    label: 'Weekday, 8am',
                    key: 'weekday-8am'
                },
                {
                    label: "Weekday, Noon",
                    key: "weekday-12pm"
                },
                {
                    label: 'Weekday, 8pm',
                    key: 'weekday-8pm'
                },
                {
                    label: 'Weekday, 11pm',
                    key: 'weekday-> 10pm'
                },
                {
                    label: 'Saturday, mid-day',
                    key: 'weekend-2pm'
                }
            ],
          scenarios: [
              {
                  key: '2020_pre_covid',
                  name: 'Full Pre-COVID Service'
              },
              {
                  key: '2022_proposal',
                  name: 'SFMTA Proposal'
              },
              {
                  key: '2022_frequent',
                  name: 'SFMTA "Frequent" Alternative'
              },
            //   {
            //       key: '2021_august',
            //       name: 'Today'
            //   },
            //   {
            //       key: '2022_frequent',
            //       name: '2022 "Frequent" Alternative'
            //   },
            //   {
            //       key: '2022_familiar',
            //       name: '2022 "Familiar" Alternative'
            //   },
            //   {
            //       key: '2022_hybrid',
            //       name: '2022 "Hybrid" Alternative'
            //   }
          ],
          selectedTrip: null,
          selectedTripIndex: 0,
          walkingShapes: {}
      }
  },
  mounted: function(){
      this.setupMap();

      window.addEventListener('resize', () => {
          this.windowSize = window.innerWidth || document.documentElement.clientWidth || 
document.body.clientWidth
      });
  },
  watch: {
      directionsUrl: function(){
          this.directions = {};
      },
      selectedTrip: function(){
          this.selectedTripIndex++;

          var data = {
              type: 'FeatureCollection',
              features: []
          }

          if(this.breakpoint != 'large'){
              window.scrollTo(0, 0);
          }

          if(this.selectedTrip && this.origin && this.destination){
              data.features = this.selectedTrip.legs.flatMap((leg) => {
                  leg.shapes = leg.shapes.map((shape) => {
                      shape.properties.leg_color = leg.color;
                      shape.properties.casing_color = leg.casing_color;
                      return shape;
                  })
                  return leg.shapes;
              }).concat([
                  {
                      type: "Feature",
                      properties: {
                          "type": "origin",
                          "name": this.origin.address.label.split(", ")[0]
                      },
                      geometry: {
                          "type": "Point",
                          coordinates: this.origin.center
                      }
                  },
                  {
                      type: "Feature",
                      properties: {
                          "type": "destination",
                          "name": this.destination.address.label.split(", ")[0]
                      },
                      geometry: {
                          "type": "Point",
                          coordinates: this.destination.center
                      }
                  }
              ])
          }
          
          this.directionsBbox = [
              [Math.min(this.origin.center[0],this.destination.center[0]),Math.min(this.origin.center[1],this.destination.center[1])],
              [Math.max(this.origin.center[0],this.destination.center[0]),Math.max(this.origin.center[1],this.destination.center[1])]
          ]

          this.updateWalkingShapes();

          this.map.getSource('route').setData(data)
          this.map.fitBounds(this.directionsBbox, {
              animate: false,
              padding: this.breakpoint == 'large' ? 96 : 20
          });
      }
  },
  components: {
      LocationSearch,
      NButton,
      NSpin,
      NDropdown,
      NTimeline,
      NTimelineItem
  },
  computed: {
      directionsUrl: function(){
            if(!this.origin || !this.destination){ return }

            var url = 'https://2ewmeimg3m.us-west-2.awsapprunner.com/directions'
            //var url = 'http://localhost:5000/directions';
            
            
            //var url = 'https://create-sf-transit-isochrones.herokuapp.com/directions'
            //var url = 'https://transit-isochrone-creator-cloud-run-eb3lcgtmfa-uw.a.run.app/directions'
            //var url = 'https://transit-isochrone-creator-llrpd.ondigitalocean.app/directions';
            url += '?start_lon=' + this.origin.center[0]
                + '&start_lat=' + this.origin.center[1]
                + '&end_lon=' + this.destination.center[0]
                + '&end_lat=' + this.destination.center[1]
                + '&max_walk_time=9'
                + '&day_of_week=' + encodeURIComponent(this.selectedTimeOfDay.key.split('-')[0])
                + '&time_of_day=' + encodeURIComponent(this.selectedTimeOfDay.key.split('-')[1])
                + '&walk_speed=' + encodeURIComponent(this.selectedWalkSpeed.key)
                + '&scenarios=' + encodeURIComponent( this.scenarios.map((x) => { return x.key }).join(','))
            return url;
      },
      selectedTimeOfDay: function(){
          return this.timeOptions[this.selectedTimeOfDayIndex];
      },
      selectedWalkSpeed: function(){
          return this.walkSpeedOptions[this.selectedWalkSpeedIndex];
      },
      breakpoint: function(){
          return this.windowSize > 700 ? 'large' : 'small'
      }
  },
  methods: {
      getDirections: function(){
            this.selectedTrip = null;
            this.directions = {};

            this.loading = true;
            this.noResults = false;


            

            fetch(this.directionsUrl).then((result) => {
                return result.json();
            }).then((result) => {
                this.loading = false;
                Object.keys(result).forEach((scenario) => {
                    Object.keys(result[scenario]).forEach((trip) => {
                        result[scenario][trip].legs = result[scenario][trip].legs.map((leg) => {
                            var code = leg.route_code;
                            if(code.length > 1){
                                code = code.replace(/[SNPVELC]/g,'')
                            }

                            code = code.trim().toUpperCase();

                            leg.friendly_code = code;
                            leg.friendly_code_long = code;

                            if(leg.offset_route_codes){
                                leg.offset_route_codes = leg.offset_route_codes.split(',').map((c) => {
                                    if(c.length > 1){
                                        c = c.replace(/[SNPVELC]/g,'')
                                    }
                                    return c.trim().toUpperCase();
                                }).filter((c) => {
                                    return c && c.length > 0
                                })

                                if(leg.offset_route_codes.indexOf(leg.friendly_code) < 0){
                                    leg.offset_route_codes.push(leg.friendly_code);
                                }

                                leg.friendly_code = leg.offset_route_codes.join('/')
                                leg.friendly_code_long = leg.offset_route_codes.map((c,index) => {
                                    if(index == leg.offset_route_codes.length - 1){
                                        return 'or ' + c
                                    }
                                    return c
                                }).join(leg.offset_route_codes.length == 2 ? ' ' : ', ');
                            }

                            if(this.route_colors[code]){
                                leg.color = this.route_colors[code];
                                leg.casing_color = this.casing_colors[code];
                            } else if (code.indexOf("R") > 0){
                                leg.color = this.route_colors["Rapid"];
                                leg.casing_color = this.casing_colors["Rapid"];
                            } else {
                                leg.color = this.route_colors["Bus"];
                                leg.casing_color = this.casing_colors["Bus"]
                            }

                            return leg
                        })
                    })
                })
                this.directions = result;
                if(Object.keys(result).length == 0){
                    this.noResults = true;
                }
            }).catch((err) => {
                this.noResults = true;
                this.directions = {};
                this.loading = false;
            })
      },
      updateWalkingShapes: function(a,b){
        var startIndex = this.selectedTripIndex;

        if(!this.selectedTrip || !this.origin || !this.destination){ 
            this.map.getSource('walking').setData({
                            type: "FeatureCollection",
                            features: []
                        })
            return
        }



        var trips = [];
        var tripStart = [this.origin.center.concat([]).reverse(), this.selectedTrip.legs[0].shapes.filter((f) => { return f.properties.position == 'start'; })[0].geometry.coordinates.concat([]).reverse() ];
        var tripEnd = [this.selectedTrip.legs[this.selectedTrip.legs.length - 1].shapes.filter((f) => { return f.properties.position == 'end'; })[0].geometry.coordinates.concat([]).reverse(), this.destination.center.concat([]).reverse()];

        if(this.selectedTrip.legs.length == 2){
            trips.push([
                this.selectedTrip.legs[0].shapes.filter((f) => { return f.properties.position == 'end'; })[0].geometry.coordinates.concat([]).reverse(),
                this.selectedTrip.legs[1].shapes.filter((f) => { return f.properties.position == 'start'; })[0].geometry.coordinates.concat([]).reverse()])
        }

        trips.push(tripStart);
        trips.push(tripEnd);

        //var done = 0;
        //var total = trips.length;
        var shapes = [];

        trips.forEach((trip) => {
            var key = trip[0].join(',') + '_' + trip[1].join(',')

            if(this.walkingShapes[key]){
                shapes = shapes.concat(this.walkingShapes[key]);

                this.map.getSource('walking').setData({
                                type: "FeatureCollection",
                                features: shapes
                            })
                return
            }

            var url = 'https://router.hereapi.com/v8/routes?apiKey=7qO4E0i5DEJtVQcR-3SCIppmDV3mGZADDbrQyrYkYDo&transportMode=pedestrian&origin=' + trip[0].join(',') + '&destination=' + trip[1].join(',') + '&return=polyline';
            fetch(url).then((result) => {
                return result.json();
            }).then((result) => {
                if(result.routes && result.routes[0] && result.routes[0].sections){
                    var theseShapes = [];
                    result.routes[0].sections.forEach((section) => {
                        theseShapes.push(decodePolyline(section.polyline));
                    })
                    //done++;

                    var walkingFeatures = theseShapes.map((shape) => {
                                return {
                                    type: "Feature",
                                    geometry: {
                                        type: "LineString",
                                        coordinates: shape.polyline.map((coord) => {
                                            return coord.reverse();
                                        })
                                    }
                                }
                            });

                    this.walkingShapes[key] = walkingFeatures
                    shapes = shapes.concat(walkingFeatures);

                    if(this.selectedTripIndex == startIndex){
                        this.map.getSource('walking').setData({
                            type: "FeatureCollection",
                            features: shapes
                        })
                    }
                }
            }).catch((err) => {
                console.log("Error getting walking directions polyline")
                console.log(err);
            })
        });
      },
      setupMap: function(){
            mapboxgl.accessToken = 'pk.eyJ1IjoiY2FydmluIiwiYSI6IjdGSUItY1kifQ.dhkr11vInR8pEWrMYMY4gA';
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: 'mapbox://styles/carvin/cku1mip38522m18pe6srplltu', // style URL
                bounds: [-122.55773212971279,37.68668744194065,-122.31798936733333,37.847777582239516]
            });

            this.map.on('load',() => {
                this.map.addSource('route',{
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                })

                this.map.addSource('walking',{
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                })

                this.map.addLayer({
                    'id': 'route-casing',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': ['get','casing_color'],
                        'line-width': 8
                    }
                });

                this.map.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': ['get','leg_color'],
                        'line-width': 4
                    }
                });

                this.map.addLayer({
                    'id': 'walking-casing',
                    'type': 'line',
                    'source': 'walking',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#ffffff',
                        'line-width': 9
                    }
                });

                this.map.addLayer({
                    'id': 'walking',
                    'type': 'line',
                    'source': 'walking',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#111111',
                        'line-width': 4,
                        'line-dasharray': [2,2]
                    }
                });

                this.map.addLayer({
                    'id': 'stops',
                    'type': 'circle',
                    'source': 'route',
                    'paint': {
                        'circle-radius': 4,
                        'circle-color': '#fff',
                        'circle-stroke-color': ['get','casing_color'],
                        'circle-stroke-width': 2
                    },
                    filter: ['==', 'type', 'transit_stop']
                });

                this.map.addLayer({
                    'id': 'destination',
                    'type': 'circle',
                    'source': 'route',
                    'paint': {
                        'circle-radius': 7,
                        'circle-color': '#ffffff',
                        'circle-stroke-color': '#111',
                        'circle-stroke-width': 2
                    },
                    filter: ["any",['==', 'type', 'destination'],['==', 'type', 'origin']]
                });

                this.map.addLayer({
                    'id': 'destination-text',
                    'type': 'symbol',
                    'source': 'route',
                    'layout': {
                        'text-field': ['get', 'name'],
                        'text-font': [
                            'DIN Pro Bold',
                            'Arial Unicode MS Bold'
                        ],
                        'text-offset': [0, 0],
                        'text-anchor': 'top',
                        'text-size': 14,
                        'icon-image': 'circle-white-2',
                        'icon-size': 0.7
                    },
                    'paint': {
                        'text-halo-color': '#ffffff',
                        'text-halo-width': 2,
                        'text-translate': [0,12],
                        'text-translate-anchor': 'viewport'
                    },
                    filter: ["any",['==', 'type', 'destination'],['==', 'type', 'origin']]
                });

            });
      },
      changeTime: function(e){
          var newValue = e;
          var newIndex;

          for(var i = 0; i < this.timeOptions.length; i++){
              if(this.timeOptions[i].key == e){
                  newIndex = i;
                  break;
              }
          }

          if(newIndex != null){
              this.selectedTimeOfDayIndex = newIndex;
          }
      },
      changeWalkSpeed: function(e){
          var newValue = e;
          var newIndex;

          for(var i = 0; i < this.walkSpeedOptions.length; i++){
              if(this.walkSpeedOptions[i].key == e){
                  newIndex = i;
                  break;
              }
          }

          if(newIndex != null){
              this.selectedWalkSpeedIndex = newIndex;
          }
      }
  }
}
</script>

<style>
@media(max-width: 1002px){
    a.trip-option, a.trip-option:first-of-type, a.trip-option:last-of-type {
        width: calc(100% + 48px);
        transform: translate3d(-24px,0,0);
        border-left: 0;
        border-right: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 24px;
        padding-right: 24px;
    }
}

.scenario-card {
    width: 100%;
    display: block;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 8px 16px rgba(0,0,0,0.05);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 16px;
}

.spinner-container {
    margin: 24px auto;
    width: 48px;
    height: 48px;
}

.trip-option {
    color: #222;
    text-decoration: none;
    display: block;
    width: 100%;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    padding: 10px 16px 10px 16px;
    background-image: url(/caret.svg);
    background-position: top 16.5px right 16px;
    background-repeat: no-repeat;
    background-size: 14px auto;
}

.trip-option:hover {
    background-color: rgba(0,0,0,0.03);
}

.trip-option:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.trip-option:last-of-type {
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 30px;
}

h2.trip-group-title {
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    display: block;
    width: 100%;
    margin: 8px 0 8px 0;
    padding: 0;
}

.route-codes {
    white-space: nowrap;
    width: 110px;
    float: left;
    padding-top: 10px;
}

.route-info {
    float: right;
    width: calc(100% - 110px);
    overflow: hidden;
}

.route-info div {
    display: block;
    width: 100%;
    text-align: right;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
}

.route-info .route-info-total {
    font-weight: 700;
    padding-right: 15px;
    box-sizing: border-box;
}

@media(max-width: 1020px){
    .route-info div {
        font-size: 14px;
    }

    .route-info .route-info-total {
        padding-right: 11px;
    }

    .trip-option {
    background-position: top 17px right 23px;
    background-size: 12px auto;
    }
}

.route-info .route-info-walking {
    font-weight: 500;
}

.route-codes > .route-code {
    display: inline-block;
    min-width: 36px;
    box-sizing: border-box;
    padding: 0 8px;
    height: 36px;
    border-radius: 18px;
    line-height: 36px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin-right: 19px;
    position: relative;
}

.route-codes > .route-code:before {
    display: block;
    position: absolute;
    height: 36px;
    width: 18px;
    background-repeat: no-repeat;
    content: " ";
    font-size: 0;
    background-image: url(/caret.svg);
    background-position: center;
    top: 0;
    left: -21px;
    background-size: 14px auto;
    opacity: 0.8;
}

.route-codes > .route-code:first-of-type:before {
    display: none;
}

.dropdown-form-field .n-button {
    border: 1px solid #dedede;
    letter-spacing: -1%;
}

.n-button__content {
    font-weight: 500;
}

.form-field-half {
    display: inline-block;
    max-width: 132px;
    margin-right: 6px;
}

.form-field-half:last-of-type {
    margin-right: 0;
}

h1 {
    font-size: 21px;
    margin: 8px 0;
    padding: 0;
}

.half-fields {
    width: 100%;
}

@media(max-width: 700px) {
    body {
        overflow: auto;
        height: 100%;
    }

    .panel {
        width: 100%;
        max-width: none;
        height: auto;
        overflow: hidden;
    }
    

    .trip-selected .panel {
        pointer-events: none;
        height: 100%;
        overflow: hidden;
    }

    .trip-selected .panel > * {
        pointer-events: auto;
    }

    .map-container {
        left: 0;
        right: 0;
        width: auto;
        top: 48px;
        height: 200px;
    }

    .panel-content-details {
        margin-top: 248px;
        background: white;
        height: calc(100% - 248px);
        overflow: auto;
    }

    .dark-bg.nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20000;
    }
}

a.back-button {
    font-size: 0;
    color: transparent;
    display: inline-block;
    width: 48px;
    height: 48px;
    background-image: url(/arrow_back_black_24dp.svg);
    opacity: 0.9;
    background-repeat: no-repeat;
    vertical-align: top;
    background-position: center;
    margin-right: 8px;
    margin-left: 7px;
}

a.back-button + h1 {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    height: 48px;
    line-height: 48px;
    margin: 0;
    padding: 0;
}

a.back-button:hover {
    background-color: rgba(0,0,0,0.1);
}

.dark-bg.nav {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 0;
}

.timeline {
    margin-top: 8px;
}

.timeline p {
    font-size: 16px;
}

.timeline .n-timeline .n-timeline-item .n-timeline-item-content .n-timeline-item-content__meta {
    color: #333;
}

.field-origin {
    margin-bottom: -1px;
}

.field-origin .n-input.n-input--resizable {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.field-destination .n-input.n-input--resizable {
    border-top-left-radius: 0px;
    border-top-right-radius: 0;
    border-bottom: 0;
}

.map-cover p {
    display: inline-block;
    font-size: 18px;
    width: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    left: 50%;
    font-weight: bold;
    background: white;
    padding: 8px;
    border-radius: 3px;
}

.timeline .n-timeline-item-content__title {
    margin-top: 0 !important;
}
</style>
